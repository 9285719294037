<template>
  <!-- Wrapper -->
  <div class="relative w-full z-50">
    <div
      v-if="model && model.length"
      class="absolute inset-0 px-3 py-3 text-black-300 z-0"
    >
      <span v-if="gues.length" v-text="replaceChars(gues, model)"></span>
    </div>
    <!-- Input -->
    <input
      :value="model"
      class="relative z-10"
      :class="context.classes.input"
      :placeholder="placeholder"
      type="text"
      :disabled="context.disabled"
      @input="inputValidate"
      @focusin="isFocused = true"
      @focusout="isFocused = false"
    />
    <!--Suggestions-->
    <Transition v-bind="fade">
      <ul
        v-if="
          suggestions.length &&
          isFocused &&
          !context.node.store.rule_language_exclusion
        "
        class="ring-2 ring-primary absolute rounded translate-y-2 left-0 w-full overflow-y-auto max-h-[144px]"
        :class="cardStyle"
      >
        <li
          v-for="item in suggestions"
          :key="item.value"
          class="cursor-pointer"
          :class="[context.classes.input, selectionHover]"
          v-text="guesToStr(item.data)"
          @click="setModel(item.data)"
        />
      </ul>
    </Transition>
  </div>
</template>

<script setup>
import { isObject } from 'lodash-es'
import { computed, inject, ref } from 'vue'

import useHttp from '@/core/http.js'
import { fade } from '@/utils/transitions.js'

const cardStyle =
  'rounded-lg bg-background border border-black-100 dark:border-brand-3/10 shadow dark:shadow-brand-1/10'

const selectionHover =
  'transition-colors hover:text-primary hover:dark:text-white hover:bg-primary-50 hover:dark:bg-primary-900'

const props = defineProps({
  context: {
    type: Object,
    default: () => ({}),
  },
})

const model = ref(
  props.context.value
    ? isObject(props.context.value)
      ? guesToStr(props.context.value)
      : props.context.value
    : '',
)
const pattern = /^[а-яА-Я\s]*$/

// For prevent DOM change
const placeholder = ref(props.context.placeholder || 'ФИО')

const suggestions = ref([])

const isFocused = ref(false)

function setModel(data) {
  model.value = guesToStr(data)
}

function capitalizeFirstLetter(str) {
  return str
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')
}

function guesToStr(data, parts = false) {
  const { surname, name, patronymic } = data
  const arr = [surname, name, patronymic].filter(Boolean)
  return parts
    ? { surname: surname ?? '', name: name ?? '', patronymic: patronymic ?? '' }
    : arr.join(' ')
}

const gues = computed(() => {
  if (suggestions.value.length) {
    return guesToStr(suggestions.value[0].data)
  }
  return ''
})

function replaceChars(str, model) {
  let result = ''
  for (let i = 0; i < str.length; i++) {
    if (i < model.length) {
      result += model[i]
    } else {
      result += str[i]
    }
  }
  return result
}

// Check input validity
async function inputValidate({ target }) {
  // Set model value
  model.value = capitalizeFirstLetter(target.value).trimStart()
  await getDaData(model.value)

  if (suggestions.value.length && suggestions.value[0]?.data) {
    let data = { ...suggestions.value[0].data }
    data.raw = model.value
    let co = ['surname', 'name', 'patronymic']
    let parts = data.raw.split(' ').filter((s) => s !== '').length
    co.splice(0, parts)
    co.forEach((key) => {
      data[key] = null
    })
    props.context.node.input(data)
  } else {
    let obj = {
      gender: null,
      raw: model.value,
    }
    let co = ['surname', 'name', 'patronymic']
    const modelSplit = model.value.split(' ').filter((s) => s !== '')
    co.forEach((key, index) => {
      obj[key] = modelSplit[index] || null
    })
    props.context.node.input(obj)
  }
}

// Abort
let controller = null

// Fetch dadata
async function getDaData(value) {
  controller?.abort()
  controller = new AbortController()

  await useHttp('https://nashsever51.ru/api/api/suggestions/fio', {
    method: 'POST',
    body: {
      query: value,
      parts: ['SURNAME', 'NAME', 'PATRONYMIC'],
    },
    signal: controller.signal,
  })
    .then((res) => {
      suggestions.value = res.suggestions
    })
    .catch()
}
</script>
