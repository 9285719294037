import { createAutoHeightTextareaPlugin } from '@formkit/addons'
import { ru } from '@formkit/i18n'
import { genesisIcons } from '@formkit/icons'
import { generateClasses } from '@formkit/themes'
import { createInput } from '@formkit/vue'

import { Date } from '@/components/formkit/Date/index.js'
import Empty from '@/components/formkit/Empty.vue'
import { Fio } from '@/components/formkit/Fio/index.js'
import { Phone } from '@/components/formkit/Phone/index.js'
import { Select } from '@/components/formkit/Select/index.js'
import { Switch } from '@/components/formkit/Switch/index.js'
import { Uploader } from '@/components/formkit/Uploader/index.js'
import { asteriskPlugin } from '@/plugins/formkit-plugins.js'

import tailwindTheme from './formkit.theme.js'

function createLoadingCallback() {
  return function loadingCallback(node) {
    if (node.props.type !== 'file') return
    node.addProps(['loading'])
    if (node.props.loading)
      node.on('loading', (value) => node.props.loading(value.payload))
  }
}

export default {
  locales: { ru },
  locale: 'ru',
  icons: {
    ...genesisIcons,
    checkboxDecorator: `<svg  xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="3"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-check"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M5 12l5 5l10 -10" /></svg>`,
  },
  plugins: [
    asteriskPlugin,
    createLoadingCallback(),
    createAutoHeightTextareaPlugin(),
  ],
  config: {
    classes: generateClasses(tailwindTheme),
  },
  inputs: {
    image: createInput(Empty),
    phone: createInput(Phone),
    file: createInput(Uploader, {
      family: 'files',
      props: [
        'max',
        'max-parallel',
        'multiple',
        'accept',
        'max-size',
        'enable-sorting',
      ],
    }),
    switch: createInput(Switch, {
      props: ['title', 'duration', 'width'],
    }),
    fio: createInput(Fio, {
      props: ['placeholder'],
    }),
    date: createInput(Date, {
      props: [
        'maska',
        'placeholder',
        'range',
        'presetRanges',
        'minRange',
        'maxDate',
        'minDate',
        'startDate',
        'maxRange',
        'enableTimePicker',
        'timePicker',
        'weekPicker',
        'monthPicker',
        'yearPicker',
        'utc',
        'locale',
        'is24',
        'enableSeconds',
        'monthNameFormat',
        'hideInputIcon',
        'clearable',
        'closeOnScroll',
        'autoApply',
        'markers',
        'highlight',
        'highlightWeekDays',
        'highlightDisabledDays',
        'disabled',
        'readonly',
        'noSwipe',
        'allowedDates',
        'textInput',
        'cancelText',
        'selectText',
      ],
    }),
    dropdown: createInput(Select, {
      props: [
        'fuseKeys',
        'placeholder',
        'options',
        'labelBy',
        'trackBy',
        'emptyPlaceholder',
        'grouped',
        'multiple',
      ],
    }),
  },
}
